<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <reviews-grid
      :rating="rating"
      :average-rating="averageRating"
      :max-rating="MAX_REVIEW_RATING"
      :reviews="reviews"
    />
  </b-overlay>
</template>

<script>
import { BOverlay } from "bootstrap-vue";
import ReviewsGrid from "@/views/reviews/list/components/ReviewsGrid.vue";
import { notifyError } from "@methods";
import { MAX_REVIEW_RATING } from "@appConfig";

export default {
  components: {
    BOverlay,
    ReviewsGrid,
  },
  data() {
    return {
      MAX_REVIEW_RATING,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["owner/loading"];
    },
    owner() {
      return this.$store.getters["owner/owner"];
    },
    rating() {
      return this.$store.getters["owner/rating"];
    },
    averageRating() {
      return this.$store.getters["owner/averageRating"];
    },
    reviews() {
      return this.$store.getters["owner/reviews"];
    },
  },
  created() {
    if (!this.owner) this.fetchOwner();
  },
  methods: {
    fetchOwner() {
      const userData = JSON.parse(
        localStorage.getItem("foravilaOwnersUserData")
      );
      const ownerUuid = userData?.owner?.uuid || null;
      this.$store.dispatch("owner/fetchOwner", ownerUuid).catch(() => {
        notifyError(
          this.$t("errors.fetchBookings.title"),
          this.$t("errors.fetchBookings.text")
        );
      });
    },
  },
};
</script>
